<template>
  <div class="ticket-item">
    <d-search
      :searchData="searchData"
      @searchEvent="searchEvent"
      @resetEvent="resetEvent"
    />
    <div class="content">
      <div class="header">
        <el-button type="primary" size="small" @click="addFun">新增罚金项</el-button>
      </div>
      <d-lable class="contentTable"
              :tableData='tableData'
              row-key="id"
              ref="table"
              :tree-props="{children: 'options', hasChildren: 'hasChildren'}"
              :columnData='columnData'>
        <template v-slot:updateUser="{ row }">
          <span>{{ row.updateUser && row.updateUser.username }}</span>
        </template>
        <template v-slot:action="{ row }">
          <span class="mgr10 blue" @click="editFun(row)">编辑</span> 
          <span class="mgr10 blue" @click="deleteFun( row )">删除</span> 
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager"
              @change="getItemList" />
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="cancelFun">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <el-form-item label="罚金项：" prop="optionName">
            <el-input v-model="form.optionName" size="small" placeholder="请输入" maxlength="20" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="罚金金额：" prop="penalSum">
            <el-input-number v-model="form.penalSum" size="small" placeholder="请输入" :precision="2" :min="0" :max="99999.99" style="width: 100%"></el-input-number>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="form.remark" maxlength="50" show-word-limit size="small" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancelFun">取 消</el-button>
        <el-button type="primary" @click="confirmFun">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";
export default {
  name: 'TicketItem',
  components: {
    DSearch,
    DLable,
    DPaging,
  },
  data () {
    return {
      id: this.$route.query.id,
      searchData: {
        searchItem: [
          {
            label: "罚金项",
            value: "optionName",
            type: "commonInput",
            placeholder: "模糊搜索"
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {
          optionName: ''
        },
      },
      columnData: [
        { prop: 'optionName', label: '罚金项' },
        { prop: 'penalSum', label: '罚金金额' },
        { prop: 'remark', label: '备注' },
        { prop: 'updateTime', label: '更新时间' },
        { prop: 'updateUser', label: '更新人员', slotProp: true },
        { prop: 'action', label: '操作', slotProp: true },
      ],
      tableData: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      dialogVisible: false,
      title: '新增罚金项',
      form: {
        optionName: '',
        penalSum: '',
        remark: ''
      },
      rules: {
        optionName: [{ required: true, message: '请输入', trigger: 'blur' }],
        penalSum: [{ required: true, message: '请输入', trigger: 'blur' }],
      }
    }
  },
  created () {
    this.getItemList()
  },
  methods: {
    searchEvent () {
      this.pager.page = 1
      this.pager.rows = 10
      this.getItemList()
    },
    resetEvent () {
      this.searchEvent()
    },
    addFun () {
      this.setModalData('新增罚金项')
    },
    setModalData (title) {
      this.title = title
      this.dialogVisible = true
    },
    editFun ({ id, optionName, penalSum, remark }) {
      this.form = { id, optionName, penalSum, remark  }
      this.setModalData('修改罚金项')
    },
    deleteFun ({ id }) {
      this.$confirm('确定删除该罚金项？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        this.$api.merchant.disableFineItemApi({ id }).then(({ code, msg }) => {
          if(code == 200) {
            this.$message.success(msg)
            this.getItemList()
            return
          }
          this.$message.error(msg)
        })
      }).catch(() => {})
    },
    cancelFun () {
      this.form = {
        optionName: '',
        penalSum: '',
        remark: ''
      }
      this.$refs.form && this.$refs.form.clearValidate()
      this.dialogVisible = false
    },
    confirmFun () {
      this.$refs.form.validate(valid => {
        if(valid) {
          const { optionName, remark, id, penalSum } = this.form
          const Api = id ? 'updateFineItemApi' : 'addFineItemApi'
          const data = { id, optionName, remark, penalSum: Number(penalSum).toFixed(2), penalTypeId: this.id }
          this.$api.merchant[Api](data).then(({ code, msg }) => {
            if(code !== 200) {
              return this.$message.error(msg)
            }
            this.cancelFun()
            this.$message.success(msg)
            this.$nextTick(() => {
              this.getItemList()

            })
          })
        }
      })
    },
    getItemList () {
      const data = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        optionName: this.searchData.params.optionName || '',
        penalTypeId: this.id
      }
      this.$api.merchant.getFineItemListApi(data).then(({ code, data, msg }) => {
        if(code !== 200) return this.$message.error(msg)
        this.pager.count = data.total || 0
        this.tableData = (data.list || []).map(e => {
          return {
            ...e,
            penalSum: Number(e.penalSum).toFixed(2)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ticket-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;
  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }
  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
  }
}
.d-paging {
  text-align: center;
}
</style>